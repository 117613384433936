@import '../variables';

// ==================================================================
// Blocks
// ==================================================================
@mixin block() {
  padding: var(--theme--unit--base);
}

@mixin block-content() {
  @media ($mq-desktop) {
    max-width: var(--page--max-width);
  }
}
