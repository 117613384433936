/* ==================================================================
// Landing Page Theme -- CSS Variables
// ================================================================== */


:root {
  /* ==================================================================
  // Color Palette
  // ================================================================== */
  --theme--color--white: #fff;
  --theme--color--black: #132f2f;
  --theme--color--grey: rgb(238,240,240);
  --theme--color--error: rgb(180,50,50);
  --theme--color--transparent: transparent;
  --theme--color--transparent-dark: rgba(0, 0, 0, 0.25);
  --theme--color--transparent-light: rgba(238, 240, 240, 0.25);
  --theme--color--semi-transparent-light: rgba(238, 240, 240, 0.85);
  --theme--color--semi-transparent-dark: rgba(0, 0, 0, 0.85);

  --theme--color--primary: #132f2f;

  --theme--color--agreeableness: #f8ce46;
  --theme--color--agreeableness-alt: #FFF2D2;

  --theme--color--conscientiousness: #FFA665;
  --theme--color--conscientiousness-alt:#FFE8D8;

  --theme--color--extraversion: #BA6B67;
  --theme--color--extraversion-alt:#FFD7D5;

  --theme--color--neuroticism: #978AE3;
  --theme--color--neuroticism-alt: #DECFFE;

  --theme--color--openness: #419BF9;
  --theme--color--openness-alt: #D8E9FE;


  /* ==================================================================
  // Typography
  // ================================================================== */
  --theme--font--root--family: "Human", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --theme--font--heading--family: var(--theme--font--root--family);
  --theme--font--heading--family-alt: 'Noe Display';
  --theme--font--root--size: 18px;
  --theme--font--root--weight: var(--theme--font--weight--normal);
  --theme--font--root--line-height: 1.5;
  --theme--font--weight--normal: 500;
  --theme--font--weight--light: 300;
  --theme--font--size-xxl: 5rem;
  --theme--font--size-xl: 3rem;
  --theme--font--size-l: 2rem;
  --theme--font--size-m: 1.5rem;
  --theme--font--size: 1rem;
  --theme--font--size-s: 0.85rem;
  --theme--font--size-xs: 0.65rem;


  /* ==================================================================
  // Sizing / Spacing
  // ================================================================== */
  --theme--unit--small: 0.5rem;
  --theme--unit--base: 1rem;
  --theme--unit--medium: 2rem;
  --theme--unit--large: 4rem;


  /* ==================================================================
  // Radius
  // ================================================================== */
  --theme--radius: 5px;


  /* ==================================================================
  // Dropshadows
  // ================================================================== */
  --theme--shadow--base: 0 0 4px rgba(0, 0, 0, 0.2);
  --theme--shadow--inset: inset 1px 1px 4px rgba(0, 0, 0, 0.1);
  --theme--shadow--none: 0 0 0 rgba(0, 0, 0, 0);


  /* ==================================================================
  // Animation
  // ================================================================== */
  --theme--z-max: 2147483647;
  --theme--z-5: 50;
  --theme--z-4: 40;
  --theme--z-3: 30;
  --theme--z-2: 20;
  --theme--z-1: 10;
  --theme--z-0: 0;


  /* ==================================================================
  // Animation
  // ================================================================== */
  --theme--animation-timing: 360ms;
  --theme--animation-ease: ease-in-out;


  /* ==================================================================
  // Page
  // ================================================================== */
  --page--scroll-behavior: smooth; /* smooth|auto */
  --page--background: var(--theme--color--black);
  --page--color: var(--theme--color--white);
  --page--link-active--color: var(--theme--color--primary);
  --page--link-hover--color: inherit;
  --page--link--color: inherit;
  --page--max-width: 77.8vw;
}
