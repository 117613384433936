@import '../variables';

// ==================================================================
// Pinned (Four Corners)
// ==================================================================
@mixin pinned($position: absolute) {
  position: $position;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin pinned-fixed() {
  @include pinned($position: fixed);
}
