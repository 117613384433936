/**
 * Visually Hidden
 * @link: https://a11yproject.com/posts/how-to-hide-content/
 */
@mixin visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

@mixin no-break {
  white-space: nowrap;
}

@mixin no-click {
  pointer-events: none;
  cursor: default;
}

@mixin hidden {
  display: none;
}

@mixin hidden-desktop {
  @media ($desktop-min) {
    @include hidden;
  }
}

@mixin hidden-mobile {
  @media ($mobile-max) {
    @include hidden;
  }
}
