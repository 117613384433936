/* ==================================================================
// Fonts
// Resource: https://transfonter.org can prepare font files for all browsers and platforms
// ================================================================== */

@font-face {
    font-family: 'Human';
    src: url('/fonts/subset-MarkWebW03-Medium.eot');
    src: url('/fonts/subset-MarkWebW03-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-MarkWebW03-Medium.woff2') format('woff2'),
        url('/fonts/subset-MarkWebW03-Medium.woff') format('woff'),
        url('/fonts/subset-MarkWebW03-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Human';
    src: url('/fonts/subset-MarkWebW03-Light.eot');
    src: url('/fonts/subset-MarkWebW03-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/subset-MarkWebW03-Light.woff2') format('woff2'),
        url('/fonts/subset-MarkWebW03-Light.woff') format('woff'),
        url('/fonts/subset-MarkWebW03-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Noe Display';
    src: url('/fonts/subset-NoeDisplay-Regular.woff2') format('woff2'),
        url('/fonts/subset-NoeDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noe Display';
    src: url('/fonts/subset-NoeDisplay-Bold.woff2') format('woff2'),
        url('/fonts/subset-NoeDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noe Display';
    src: url('/fonts/subset-NoeDisplay-BoldItalic.woff2') format('woff2'),
        url('/fonts/subset-NoeDisplay-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Noe Display';
    src: url('/fonts/subset-NoeDisplay-RegularItalic.woff2') format('woff2'),
        url('/fonts/subset-NoeDisplay-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
